<template>
    <div id="app" ref="app" @scroll="handleScroll">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />


        <el-backtop target="#app" style="z-index: 100;"></el-backtop>

        <div v-if="showDuck" class="popover" v-drag>
            <el-image class="popover-img" src="/web/image/logo0.png">
            </el-image>
            <div class="popover-item first bold" @click="to('/main/download')"><a>{{ $t('题库下载') }}</a></div>
            <div class="popover-line"></div>
            <div class="popover-line"></div>
            <el-popover placement="left" width="300" :offset="0" trigger="hover">
                <el-image class="qrode-img" :src="`/web/image/home/app_qrcode${$store.state.lang}.jpg`"></el-image>
                <div class="popover-item bold" slot="reference"><a>APP{{ $t('下载') }}</a></div>
            </el-popover>
            <div class="popover-line"></div>
            <el-popover placement="left" width="300" :offset="0" trigger="hover">
                <el-image class="qrode-img" :src="$host + $store.state.setting.kf_qrcode"></el-image>
                <div class="popover-item bold" slot="reference"><a>{{ $t('联系客服') }}</a></div>
            </el-popover>
            <div class="popover-line"></div>


            <div @click="to('/media?tag=article&id=4')" class="popover-item bold" slot="reference"><a>{{ $t('关于我们') }}</a>
            </div>
            <div class="popover-line"></div>
        </div>
    </div>
</template>

<script>

export default {


    data() {
        return {
            showDuck: true,
            top: "40%",
            left: "calc(100vw - 160px)",
            dragging: false,
            scrollInfo: {},
        }
    },

    directives: {
        drag(el) {
            el.style.cursor = 'move'

            el.onmousedown = function (e) {
                var disx = e.pageX - el.offsetLeft;
                var disy = e.pageY - el.offsetTop;

                if (e.preventDefault) {
                    e.preventDefault()
                } else {
                    e.returnValue = false  // 解决快速频繁拖动滞后问题
                }

                document.onmousemove = function (e) {
                    // 鼠标位置-鼠标相对元素位置=元素位置
                    let left = e.clientX - disx;
                    let top = e.clientY - disy;
                    // 限制拖拽范围不超出可视区
                    if (left <= 0) {
                        left = 5    // 设置成5,离边缘不要太近
                    } else if (left > document.documentElement.clientWidth - el.clientWidth) { // document.documentElement.clientWidth屏幕可视区宽度
                        left = document.documentElement.clientWidth - el.clientWidth - 5
                    }

                    if (top <= 0) {
                        top = 5
                    } else if (top > document.documentElement.clientHeight - el.clientHeight) {
                        top = document.documentElement.clientHeight - el.clientHeight - 5
                    }
                    el.style.left = left + 'px'
                    el.style.top = top + 'px'
                }

                document.onmouseup = function () {
                    document.onmousemove = document.onmouseup = null
                }
            }
        }
    },

    computed: {
        lang: function () {
            return this.$store.state.lang;
        }
    },

    watch: {
        lang() {
            window.location.reload();
        },
        $route(route) {
            if (route.path == '/mock' || route.path == '/privacy') {
                this.showDuck = false;
            } else {
                this.showDuck = true;
            }
        }
    },

    created() {
        this.$store.dispatch("fetch_courses");
        this.$http.post("/api/sorts").then((sorts) => {
            let types = [];
            sorts.forEach((sort) => {
                sort.types.forEach((type) => {
                    types.push(type);
                })
            })
            this.$store.commit("setSorts", sorts);
            this.$store.commit("setTypes", types);
        })

        this.$http.post("/api/user/info").then((user) => {
            this.$store.commit("setUser", user);
        })

        this.$http.post("/api/setting").then((setting) => {
            this.$store.commit("setSetting", setting);
        })

        this.$http.post("/api/article/list").then((list) => {
            this.$store.commit("setArticles", list);
        })
        

        // this.$nextTick(() => {
        //     // 禁用右键
        //     document.oncontextmenu = new Function("event.returnValue=false");
        //     // 禁用选择
        //     document.onselectstart = new Function("event.returnValue=false");
        // });

        this.$bus.$on("scrollTop", (top) => {
            if (top == undefined) {
                let key = this.$route.path.replace("/", "_");
                top = this.scrollInfo[key] || 0;
            }
            this.$refs.app.scrollTop = top;
        })
    },


    methods: {
        to(url) {
            this.$router.push(url)
        },
        handleScroll(e) {
            let key = this.$route.path.replace("/", "_");
            this.scrollInfo[key] = e.target.scrollTop;
            this.$bus.$emit("scroll", e.target.scrollTop)
        }
    }
}


</script>



<style>
#app {
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    min-width: 1220px;
}

.popover {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100px;
    top: 55%;
    left: calc(100vw - 100px);
    /* border-radius: 8px; */
    /* background-color: #fff; */
    position: fixed;
    z-index: 888;
    overflow: hidden;
    /* border: 1px solid #eee; */
    transition: height 0.1s ease-in;
    width: 100px;
}

.popover:hover {
    height: 320px;
    transition: height 0.1s ease-out;
}

.popover-item {
    font-size: 16px;
    background: #fff;
    text-align: center;
    padding: 15px 10px;
    cursor: pointer;
}

.first {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.end {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.popover-img {
    margin: 10px;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    cursor: pointer;
}

.qrode-img {
    width: 300px;
    height: 300px;
}

.popover-line {
    flex-shrink: 0;
    height: 1px;
    margin: 0 10px;
    width: 40px;
    background-color: #aaa;
}
</style>
