import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter); //全局方法 Vue.use() 使用插件，需要在你调用 new Vue() 启动应用之前完成：


export default new VueRouter({
    saveScrollPosition: true,
    mode: "history",
    // base: "/web/",
    routes: [
        {
            path: '',
            component: resolve => void (require(['@/views/common/Home.vue'], resolve)),
            children: [
                {
                    path: '',
                    meta: {
                        keepAlive: true
                    },
                    component: resolve => void (require(['@/views/Index.vue'], resolve)),
                },
                // {
                //     name: 'index',
                //     path: 'index',
                //     meta: {
                //         keepAlive: true
                //     },
                //     component: resolve => void (require(['@/views/Index.vue'], resolve)),
                // },
                {
                    name: 'login',
                    path: 'login',
                    component: resolve => void (require(['@/views/Login.vue'], resolve)),
                },
                {
                    name: 'regist',
                    path: 'regist',
                    component: resolve => void (require(['@/views/Regist.vue'], resolve)),
                },
                {
                    name: 'course',
                    path: 'course',
                    component: resolve => void (require(['@/views/Course.vue'], resolve)),
                },
                {
                    name: 'article',
                    path: 'article',
                    component: resolve => void (require(['@/views/Article.vue'], resolve)),
                },
                {
                    name: 'media',
                    path: 'media',
                    component: resolve => void (require(['@/views/Media.vue'], resolve)),
                },
                {
                    name: 'record',
                    path: 'record',
                    component: resolve => void (require(['@/views/Record.vue'], resolve)),
                },
            ]
        },
        {
            name: 'mock',
            path: '/mock',
            component: resolve => void (require(['@/views/Mock.vue'], resolve)),
        },

        {
            name: 'privacy',
            path: '/privacy',
            component: resolve => void (require(['@/views/Privacy.vue'], resolve)),
        },
        {
            name: 'main',
            path: '/main',
            component: resolve => void (require(['@/views/common/Main.vue'], resolve)),
            children: [
                {
                    name: 'main-media',
                    path: '/main/media',
                    component: resolve => void (require(['@/views/main/Media.vue'], resolve)),
                },
                {
                    name: 'main-article',
                    path: '/main/article',
                    component: resolve => void (require(['@/views/main/Article.vue'], resolve)),
                },
                {
                    name: 'main-index',
                    path: '',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Index.vue'], resolve)),
                },
                {
                    name: 'practise',
                    path: '/main/practise',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Practise.vue'], resolve)),
                },

                {
                    name: 'record',
                    path: '/main/record',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Record.vue'], resolve)),
                },

                {
                    name: 'record_detail',
                    path: '/main/record/detail',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/RecordDetail.vue'], resolve)),
                },

                {
                    name: 'vocabulary',
                    path: '/main/vocabulary',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Vocabulary.vue'], resolve)),
                },

                {
                    name: 'favorites',
                    path: '/main/favorites',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Favorites.vue'], resolve)),
                },

                {
                    name: 'documents',
                    path: '/main/documents',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Documents.vue'], resolve)),
                },

                {
                    name: 'pte',
                    path: '/main/pte',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Pte.vue'], resolve)),
                },

                {
                    name: 'ccl',
                    path: '/main/ccl',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Ccl.vue'], resolve)),
                },

                {
                    name: 'mocks',
                    path: '/main/mocks',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Mocks.vue'], resolve)),
                },

                {
                    name: 'mockRecord',
                    path: '/main/mock/record',
                    meta: { auth: 1, keepAlive: true },
                    component: resolve => void (require(['@/views/main/MockRecord.vue'], resolve)),
                },
                {
                    name: 'wordbook',
                    path: '/main/wordbook',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Wordbook.vue'], resolve)),
                },
                {
                    name: 'plan',
                    path: '/main/plan',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Plan.vue'], resolve)),
                },

                {
                    name: 'planAdd',
                    path: '/main/plan/add',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/PlanAdd.vue'], resolve)),
                },

                {
                    name: 'vip',
                    path: '/main/vip',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Vip.vue'], resolve)),
                },
                {
                    name: 'personal',
                    path: '/main/personal',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Personal.vue'], resolve)),
                },
                {
                    name: 'faq',
                    path: '/main/faq',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Faq.vue'], resolve)),
                },
                {
                    name: 'certificate',
                    path: '/main/certificate',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Certificate.vue'], resolve)),
                },
                {
                    name: 'search',
                    path: '/main/search',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Search.vue'], resolve)),
                },
                {
                    name: 'download',
                    path: '/main/download',
                    meta: { auth: 1 },
                    component: resolve => void (require(['@/views/main/Download.vue'], resolve)),
                },
            ]
        },


    ]
})
